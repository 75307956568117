<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" dense flat>
          <v-toolbar-title>Sales Employee: {{salesEmployee.SlpName}}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-tabs>
            <v-tab :to="`/sales/employee/${id}`">Calls</v-tab>
            <v-tab :to="`/sales/employee/${id}/orders`">Orders</v-tab>
            <v-tab :to="`/sales/employee/${id}/regions`">Assigned Regions</v-tab>
          </v-tabs>

          <v-row>
            <v-divider></v-divider>
          </v-row>

          <router-view/>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      salesEmployee: {}
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true
    }
  },
  methods: {
    getData(val) {
      const self = this;
      self.id = val;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee/${val}`)
        .then(res => {
          self.salesEmployee = res;
          self.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created () {
    
  },
};
</script>

<style lang="scss" scoped>
</style>